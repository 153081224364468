import { Marker } from "react-map-gl";
import "./ImageMarker.scss";

const ImageMarker = ({ destination }) => {
	return (
		<Marker longitude={destination.lng} latitude={destination.lat} style={{ zIndex: !destination?.image ? 0 : 10 }}>
			<div className={`image-destination-marker ${!destination?.image ? "no-image" : ""}`}>
				{destination?.image ? <img src={`https://media.stippl.io/${destination?.image}`} style={{ objectFit: "cover" }} /> : null}
			</div>
		</Marker>
	);
};

export default ImageMarker;
